var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"warehouse.place_of_deliveries","resource":"/management/agency","creatable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){return _vm.editItem(item)}}})],1)]}},{key:"consignee",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.consignee,"noTranslate":""}})],1)]}},{key:"tel",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.tel,"noTranslate":""}})],1)]}},{key:"full_address",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.full_address,"noTranslate":""}})],1)]}},{key:"sender_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.sender_name,"noTranslate":""}})],1)]}},{key:"sender_tel",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.sender_tel,"noTranslate":""}})],1)]}},{key:"sender_full_address",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.sender_full_address,"noTranslate":""}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Id","value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"consignee-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Consignee","value":_vm.filter.consignee},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "consignee", $event)},_vm.filterChange]}})]},proxy:true},{key:"tel-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Tel","value":_vm.filter.tel},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "tel", $event)},_vm.filterChange]}})]},proxy:true}])},[_vm._v("= ")]),_c('SModalAddress',{attrs:{"show":_vm.showModalCreate,"type":"place_of_delivery"},on:{"update:show":function($event){_vm.showModalCreate=$event},"created":function($event){_vm.showModalCreate = false}}}),_c('SModalAddress',{attrs:{"show":_vm.showModalEdit,"type":"place_of_delivery","address":_vm.detail},on:{"update:show":function($event){_vm.showModalEdit=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }